export enum SettingsTabs {
  common,
  desktop,
}

export enum SettingsGroups {
  SupportContactsLink = 1,
  AccessRequestLink,
  RevitSharedParameterFiles,
  MaterialCodeParameterName,
}
