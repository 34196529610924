import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MAT_DATE_LOCALE, PIK_CONFIG } from '@pik-ui/ng-components';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from 'environments/environment';
import { SharedModule } from '@shared/shared.module';
import { UnauthorizedInterceptor } from '@core/interceptors';
import { CoreModule } from '@core/core.module';
import { coreComponents, SideNavigationModule } from '@core/components';
import { FmTabsService } from '@services/fm-tabs.service';
import { ServiceLocator } from '@services';
import { AuthConfigModule } from '@core/auth';

import { AppComponent } from './app.component';
import { AppState } from './store';
import { AppRoutingModule } from './app-routing.module';
import { DownloadAppButtonsModule } from '@shared/components/download-app-buttons/download-app-buttons.module';
import { LanguageChangerComponent } from '@shared/components';
import { ParameterValueControlModule } from '@shared/components/controls/parameter-value-control/parameter-value-control.module';
import { SettingsDataService } from '@features/settings/services/settings-data.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
};

export function init_settings(sd: SettingsDataService) {
  return () => sd.initData();
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '.json?cb=' + new Date().getTime());
}

@NgModule({
  /** TODO: убрать     LanguageChangerComponent */
  declarations: [AppComponent, ...coreComponents, LanguageChangerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    CoreModule,
    AuthConfigModule,
    ReactiveFormsModule,
    NgxsModule.forRoot([AppState]),
    !environment.production ? NgxsReduxDevtoolsPluginModule.forRoot() : [],
    NgxsRouterPluginModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    SharedModule,
    SideNavigationModule,
    DownloadAppButtonsModule,
    /** TODO: добавить локализацию в среды */
    TranslateModule.forRoot({
      defaultLanguage: 'ru-RU',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ParameterValueControlModule,
  ],
  providers: [
    FmTabsService,
    ServiceLocator,
    {
      provide: APP_INITIALIZER,
      useFactory: init_settings,
      deps: [SettingsDataService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    {
      provide: PIK_CONFIG,
      useValue: {
        datepicker: {
          rangeBeginKey: 'start',
          rangeEndKey: 'end',
        },
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: environment.locale },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
