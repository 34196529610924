import { Component, OnInit } from '@angular/core';
import { TitleService } from '@core/services';
import { map } from 'rxjs';
import { SettingsDataService } from '@features/settings/services/settings-data.service';

@Component({
  selector: 'app-read-only-page',
  templateUrl: './read-only-page.component.html',
  styleUrls: ['./read-only-page.component.scss'],
})
export class ReadOnlyPageComponent implements OnInit {
  accessLink$ = this.settingsDataService.settingsObject$.pipe(map((obj) => obj?.AccessRequestLink));

  constructor(private titleService: TitleService, private settingsDataService: SettingsDataService) {}

  ngOnInit() {
    this.titleService.setTitle('Family Manager');
  }
}
