import { Component, Input, OnInit } from '@angular/core';
import { BaseBindingVariantDto } from '@common/dto';

@Component({
  selector: 'app-bindings-check-nomenclature-branch',
  templateUrl: './bindings-check-nomenclature-branch.component.html',
  styleUrls: ['./bindings-check-nomenclature-branch.component.scss'],
})
export class BindingsCheckNomenclatureBranchComponent implements OnInit {
  @Input() nomenclature: Omit<BaseBindingVariantDto, 'specifications'>;

  constructor() {}

  ngOnInit(): void {}
}
