export enum FamilyType {
  RevitSystem = 'RevitSystem',
  RevitLoad = 'RevitLoad',
  RevitGroup = 'RevitGroup',
  Dwg = 'Dwg',
}

export enum ERuleParameters {
  SYMBOL_NAME = 1,
  FAMILY_NAME,
  PROJECT_DISCIPLINE,
}

export enum EConjunction {
  OR = 1,
  AND,
}
