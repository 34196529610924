import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IParameterRangeValueControlModel } from '../components/parameter-range-value-control/parameter-range-value-control.model';

@Injectable({
  providedIn: 'root',
})
export class RangeService {
  public validValues$ = new BehaviorSubject<any>(false);
  constructor() {}
}
