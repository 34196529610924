import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { PikDrawerComponent } from '@pik-ui/ng-components';
import { BehaviorSubject, Subject } from 'rxjs';
import { BindingsCheckParameter, BindingsCheckParametersDrawerSaveData } from '@features/bindings-check/interfaces';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TypedFormGroup } from '@common/interfaces';
import { BindingsCheckParametersDrawerEnum } from './bindings-check-parameters-drawer.enum';
import { BindingsCheckParameterForm, IBindingsCheckParameterForm } from './bindings-check-parameters-form.model';

@Component({
  selector: 'app-bindings-check-parameters-drawer',
  templateUrl: './bindings-check-parameters-drawer.component.html',
  styleUrls: ['./bindings-check-parameters-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BindingsCheckParametersDrawerComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(PikDrawerComponent) public readonly drawer: PikDrawerComponent;

  @Output() onSave = new EventEmitter<BindingsCheckParametersDrawerSaveData>();

  public initialId: string = null;
  public parameterForm: BindingsCheckParameterForm = new FormGroup<TypedFormGroup<IBindingsCheckParameterForm>>({});
  public drawerType: BindingsCheckParametersDrawerEnum;
  public readonly initialParameters$ = new BehaviorSubject<BindingsCheckParameter[]>([]);

  private readonly _destroy$ = new Subject();

  constructor(private formBuild: FormBuilder) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  public close() {
    this.drawer.close();
  }

  public save() {
    const formData = this.parameterForm.getRawValue();
    const parameters = this.initialParameters$.getValue().map((p) => ({
      ...p,
      valueStr: formData[p.uniqueParameterName],
    }));

    this.onSave.emit({ parameters, id: this.initialId, type: this.drawerType });
    this.drawer.close();
  }

  open(
    id: string,
    parameters: BindingsCheckParameter[],
    drawerType: BindingsCheckParametersDrawerEnum = BindingsCheckParametersDrawerEnum.Material,
  ) {
    this.drawerType = drawerType;
    this.initialId = id;
    this.initialParameters$.next(parameters);
    this.buildParametersForm(parameters);

    this.drawer.open();
    this.drawer.scrollbar.directiveRef.scrollToTop();
  }

  private buildParametersForm(parameters: BindingsCheckParameter[]) {
    const group = {};
    parameters.forEach((p) => (group[p.uniqueParameterName] = p.valueStr));
    this.parameterForm = this.formBuild.group(group);
  }
}
