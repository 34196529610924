import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MessageDto } from '@common/dto';
import { OpenTabData, TabType } from '@common/interfaces';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatMessageComponent {
  @Input() set message(message: MessageDto) {
    this._message = {
      ...message,
      message: message.message.split('/\n').join(' </br>'),
    };

    this.appOpenTab = {
      type: TabType.USERS,
      entity: {
        id: message.userId,
        name: message.fio,
      },
    };
  }

  get message() {
    return this._message;
  }

  appOpenTab: OpenTabData;

  private _message: MessageDto;
}
