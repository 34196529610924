import { BindingDto } from '@common/dto';
import { BindingEntityType } from '@features/bindings/shared/types';

export function getEntityIdType(binding: BindingDto): {
  _bindingEntityType: BindingEntityType;
  _bindingEntityId: number;
} {
  let _bindingEntityType: BindingEntityType;
  let _bindingEntityId: number;

  if (binding.family) {
    _bindingEntityType = BindingEntityType.Family;
    _bindingEntityId = binding.family.id;
  } else if (binding.functionalType) {
    _bindingEntityType = BindingEntityType.FunctionalType;
    _bindingEntityId = binding.functionalType.id;
  } else if (binding.material) {
    _bindingEntityType = BindingEntityType.Material;
    _bindingEntityId = binding.material.id;
  }

  return { _bindingEntityType, _bindingEntityId };
}
