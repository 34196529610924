import { Component, Input, OnInit } from '@angular/core';
import { ThemeColors } from '@common/consts';

@Component({
  selector: 'app-boolean-tag',
  templateUrl: './boolean-tag.component.html',
  styleUrls: ['./boolean-tag.component.scss'],
})
export class BooleanTagComponent {
  @Input() data = null;
  themeColors = ThemeColors;
}
