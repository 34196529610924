import { Component, Input } from '@angular/core';
import { BindingCheckNomenclatureData } from '@features/bindings-check/utils';
import { ROUTES_PARTS, ThemeColors } from '@common/consts';
import { BindingSourceType } from '@common/dto';
import { PermissionService } from '@core/account/services';
import { BindingEntityType } from '@features/bindings/shared/types';
import { BindingsCheckService } from '@features/bindings-check/services';

@Component({
  selector: 'app-bindings-check-binding-branch',
  templateUrl: './bindings-check-binding-branch.component.html',
  styleUrls: ['./bindings-check-binding-branch.component.scss'],
})
export class BindingsCheckBindingBranchComponent {
  @Input() set data(value: BindingCheckNomenclatureData) {
    this.setEntityType(value);
    this._data = value;
  }

  bindingId = null;

  public readonly BindingSourceType = BindingSourceType;
  public readonly colors = ThemeColors;
  public readonly ROUTES_PARTS = ROUTES_PARTS;
  public entityType: BindingEntityType;
  public entityId: number;
  public _data: BindingCheckNomenclatureData;

  constructor(public permissionService: PermissionService, public bindingsCheckService: BindingsCheckService) {}

  private setEntityType(data: BindingCheckNomenclatureData) {
    this.bindingId = data.bindingId;
    if (data.familyId) {
      this.entityId = data.familyId;
      this.entityType = BindingEntityType.Family;
      return;
    }
    if (data.materialId) {
      this.entityId = data.materialId;
      this.entityType = BindingEntityType.Material;
      return;
    }

    this.entityId = data.functionalTypeId;
    this.entityType = BindingEntityType.FunctionalType;
  }
}
