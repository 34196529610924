<ng-container *ngIf="_data.isSuccess; else invalidIcon">
  <svg-icon class="icon" src="./assets/icons/valid.svg"></svg-icon>
</ng-container>

<ng-template #invalidIcon>
  <svg-icon [pikTooltip]="_data.message" class="icon" src="./assets/icons/invalid.svg"></svg-icon>
</ng-template>

<app-binding-link [bindingId]="bindingId">
  <pik-tag large [color]="colors.Binding">
    {{ _data?.groupName }}
  </pik-tag>
</app-binding-link>
