import { Component, Input, OnInit } from '@angular/core';
import { NomenclatureUtilsService } from '@features/elements-check/services/nomenclature-utils.service';

@Component({
  selector: 'app-binding-link',
  templateUrl: './binding-link.component.html',
  styleUrls: ['./binding-link.component.scss'],
})
export class BindingLinkComponent implements OnInit {
  id: number;
  @Input() set bindingId(id: number) {
    if (!id) return;
    this.id = id;
  }

  link$ = null;

  ngOnInit(): void {
    this.link$ = this.nomenclatureUtilsService.getNomenclatureUrl(this.id);
  }

  constructor(private nomenclatureUtilsService: NomenclatureUtilsService) {}
}
