import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BindingsCheckService } from '../../services';
import { BindingCheckMaterial } from '../../interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bindings-check-materials',
  templateUrl: './bindings-check-materials.component.html',
  styleUrls: ['./bindings-check-materials.component.scss'],
})
export class BindingsCheckMaterialsComponent implements OnInit, OnDestroy {
  form = new FormGroup({});
  @Output() editMaterialParameters = new EventEmitter<BindingCheckMaterial>();

  private readonly destroy$ = new Subject();
  constructor(public bindingsCheckService: BindingsCheckService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.subscribeToMaterials().subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  trackById(index: number, item: BindingCheckMaterial) {
    return item.localId;
  }

  private subscribeToMaterials() {
    return this.bindingsCheckService.materials$.pipe(
      tap((materials) => {
        const controls = {};
        materials.forEach((m) => {
          controls[m.localId] = [m.material?.id || null];
          controls['quantity-' + m.localId] = [m.quantity || 1, [Validators.min(1), Validators.required]];
        });
        this.form = this.formBuilder.group(controls);
      }),
      takeUntil(this.destroy$),
    );
  }
}
