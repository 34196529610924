<form [formGroup]="form">
  <ng-container *ngFor="let material of bindingsCheckService.materials$ | async; trackBy: trackById">
    <div class="material-item">
      <div class="material-item__row">
        <app-material-control
          [formControlName]="material.localId"
          (selectFull)="bindingsCheckService.setMaterial(material.localId, $event)"
        ></app-material-control>

        <input
          *ngIf="form.controls[material.localId].value"
          (change)="
            bindingsCheckService.setMaterialQuantity(
              material.localId,
              form.controls['quantity-' + material.localId].value
            )
          "
          pikInput
          type="number"
          class="quantity"
          formControlName="quantity-{{ material.localId }}"
        />

        <app-material-link
          *ngIf="form.controls[material.localId].value"
          class="angle-link"
          [id]="form.controls[material.localId].value"
        >
          <svg-icon src="./assets/icons/tree-angle.svg" [stretch]="true"></svg-icon>
        </app-material-link>
        <icon-button
          icon="./assets/icons/delete.svg"
          tooltip="{{ 'COMMON.REMOVE' | translate }}"
          (click)="bindingsCheckService.removeMaterial(material.localId)"
        ></icon-button>
      </div>

      <button
        pikButton
        appearance="inverse"
        class="parameters-button"
        *ngIf="material?.parameters"
        (click)="editMaterialParameters.emit(material)"
      >
        <svg-icon src="./assets/icons/table-settings.svg" pikButtonIcon position="before"></svg-icon>
        {{ 'COMMON_LABELS.PARAMETERS' | translate }}
        <pik-tag [counter]="true">{{ material.parameters?.validParametersCount }}</pik-tag>
      </button>
    </div>
  </ng-container>
</form>

<button pikButton appearance="inverse" class="add-button" (click)="bindingsCheckService.addEmptyMaterial()">
  <svg-icon src="./assets/icons/plus.svg" pikButtonIcon position="before"></svg-icon>
  Добавить материал
</button>
