export enum Permissions {
  ChatMessageCreate = 'ChatMessageCreate',
  AdditionalFileDownload = 'AdditionalFileDownload',
  DesktopClientExtendedMode = 'DesktopClientExtendedMode',
  UserUpdate = 'UserUpdate',
  UserAttributesUpdate = 'UserAttributesUpdate',
  RoleCreate = 'RoleCreate',
  RoleUpdate = 'RoleUpdate',
  RoleDelete = 'RoleDelete',
  ProjectCreate = 'ProjectCreate',
  ProjectUpdate = 'ProjectUpdate',
  ProjectDelete = 'ProjectDelete',
  ProjectAttribute = 'ProjectAttribute',
  ProjectsExport = 'ProjectsExport',
  ProjectsImport = 'ProjectsImport',
  DisciplineCreate = 'DisciplineCreate',
  DisciplineUpdate = 'DisciplineUpdate',
  DisciplineSoftDelete = 'DisciplineSoftDelete',
  AttributeCreate = 'AttributeCreate',
  AttributeUpdate = 'AttributeUpdate',
  AttributeSoftDelete = 'AttributeSoftDelete',
  ParameterCreate = 'ParameterCreate',
  ParameterUpdate = 'ParameterUpdate',
  ParameterSoftDelete = 'ParameterSoftDelete',
  UniqueParameterCreate = 'UniqueParameterCreate',
  UniqueParameterUpdate = 'UniqueParameterUpdate',
  UniqueParameterSoftDelete = 'UniqueParameterSoftDelete',
  FunctionalTypeCreate = 'FunctionalTypeCreate',
  FunctionalTypeSoftDelete = 'FunctionalTypeSoftDelete',
  FunctionalTypeParameter = 'FunctionalTypeParameter',
  FunctionalTypeAttribute = 'FunctionalTypeAttribute',
  FamiliesExport = 'FamiliesExport',
  FamiliesImport = 'FamiliesImport',
  RequestsImport = 'RequestsImport',
  RequestExport = 'RequestExport',
  FunctionalTypeAdditionalFile = 'FunctionalTypeAdditionalFile',
  FunctionalTypeNameUpdate = 'FunctionalTypeNameUpdate',
  FunctionalTypeCodeChange = 'FunctionalTypeCodeChange',
  FunctionalTypeDescriptionUpdate = 'FunctionalTypeDescriptionUpdate',
  FunctionalTypeDisciplines = 'FunctionalTypeDisciplines',
  FunctionalTypeImageUpload = 'FunctionalTypeImageUpload',
  MaterialCreate = 'MaterialCreate',
  MaterialSoftDelete = 'MaterialSoftDelete',
  MaterialAttribute = 'MaterialAttribute',
  MaterialParameter = 'MaterialParameter',
  MaterialsExport = 'MaterialsExport',
  MaterialsImport = 'MaterialsImport',
  MaterialNameUpdate = 'MaterialNameUpdate',
  MaterialDescriptionUpdate = 'MaterialDescriptionUpdate',
  MaterialImageUpload = 'MaterialImageUpload',
  MaterialDisciplines = 'MaterialDisciplines',
  MaterialFunctionalTypeUpdate = 'MaterialFunctionalTypeUpdate',
  MaterialStatusUpdate = 'MaterialStatusUpdate',
  FolderRename = 'FolderRename',
  FolderCreateDelete = 'FolderCreateDelete',
  FolderVisibilityUpdate = 'FolderVisibilityUpdate',
  FolderMove = 'FolderMove',
  FamilyMove = 'FamilyMove',
  FamilyCopy = 'FamilyCopy',
  FamilyAttribute = 'FamilyAttribute',
  FolderFamilyDelete = 'FolderFamilyDelete',
  FamilyDelete = 'FamilyDelete',
  FamilyAdditionalFile = 'FamilyAdditionalFile',
  FamilyNameUpdate = 'FamilyNameUpdate',
  FamilyDescriptionUpdate = 'FamilyDescriptionUpdate',
  FamilyFunctionalTypeUpdate = 'FamilyFunctionalTypeUpdate',
  AllowedVersionDownload = 'AllowedVersionDownload',
  NotAllowedVersionDownload = 'NotAllowedVersionDownload',
  VersionUpload = 'VersionUpload',
  VersionUpdate = 'VersionUpdate',
  VersionDelete = 'VersionDelete',
  VersionAdditionalFile = 'VersionAdditionalFile',
  FamilyParametersExport = 'FamilyParametersExport',
  FamilyParameterValueUpdate = 'FamilyParameterValueUpdate',
  FamilyParametersImport = 'FamilyParametersImport',
  ParameterValueSetMaterial = 'ParameterValueSetMaterial',
  ParameterValueSet = 'ParameterValueSet',
  RequestCreate = 'RequestCreate',
  RequestModerate = 'RequestModerate',
  RequestApprove = 'RequestApprove',
  RequestDevelop = 'RequestDevelop',
  Nomenclature = 'Nomenclature',
  AppVersionUpload = 'AppVersionUpload',
  AppVersionDownload = 'AppVersionDownload',
  AppVersionUpdate = 'AppVersionUpdate',
  Standard = 'Standard',
  FamilyParameterValueOwnerUpdate = 'FamilyParameterValueOwnerUpdate',
  SnapBinding = 'SnapBinding',
  InternalBinding = 'InternalBinding',
  UserDisciplinesUpdate = 'UserDisciplinesUpdate',
  FunctionalTypeParametersExport = 'FunctionalTypeParametersExport',
  FunctionalTypeParametersImport = 'FunctionalTypeParametersImport',
  VisibilityOfCatalogs = 'VisibilityOfCatalogs',
  VisibilityOfBindings = 'VisibilityOfBindings',
  VisibilityOfParameters = 'VisibilityOfParameters',
  ApplicationSettingsChange = 'ApplicationSettingsChange',
  VisibilityBase = 'VisibilityBase',
  SnapBindingsExport = 'SnapBindingsExport',
  InternalBindingsExport = 'InternalBindingsExport',
  SnapBindingsImport = 'SnapBindingsImport',
  InternalBindingsImport = 'InternalBindingsImport',
  UnitTypes = 'UnitTypes',
  FunctionalTypesImport = 'FunctionalTypesImport',
  FunctionalTypesExport = 'FunctionalTypesExport',
}
