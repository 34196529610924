import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IHistoryDto, IPreparedHistoryObject } from '@common/dto/HistoryDto';
import { HistoryActions, HistoryType } from '@common/interfaces/history.interface';
import { HistoryApiService } from '@services/api/history-api.service';
import { dateToISOLikeButLocal } from '@shared/utils';
import { combineLatest, finalize, Observable, of, Subject, takeUntil } from 'rxjs';
import { DataTypeCodeEnum, DataTypeCodeEnumRu } from '@common/enums';
import { EHistoryFieldNames } from './types/history.types';

class HistoryChangeTimeFabric {
  action = null;
  author = null;
  oldDataType = null;
  newDataType = null;
  fieldName = null;
  oldValue = null;
  newValue = null;
  oldValueHelper = null;
  newValueHelper = null;
  constructor({ action, author, oldDataType, newDataType, fieldName, oldValue, newValue }) {
    this.action = action;
    this.author = author;
    this.oldDataType = this.isBooleanUsed(oldValue) ? DataTypeCodeEnum.Boolean : oldDataType;
    this.newDataType = this.isBooleanUsed(newValue) ? DataTypeCodeEnum.Boolean : newDataType;
    this.fieldName = fieldName;
    this.oldValue = Array.isArray(oldValue) ? this.getBinary(oldValue) : [oldValue];
    this.newValue = Array.isArray(newValue) ? this.getBinary(newValue) : [newValue];
  }

  isBooleanUsed(value): boolean {
    return value === 'False' || value === 'True';
  }

  getBinary(value): string {
    if (value === 'False') {
      return '0';
    } else if (value === 'True') {
      return '1';
    } else {
      return value;
    }
  }
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnDestroy {
  _destroy$ = new Subject();
  mode: HistoryType;
  dateFrom = new FormControl(null);
  dateTo = new FormControl(null);
  historyData: IPreparedHistoryObject[] = null;
  pending = false;
  today = new Date();
  HistoryActions = HistoryActions;
  DataTypeCodeEnum = DataTypeCodeEnum;

  __data: { id: number; mode: HistoryType } = null;
  @Input() set data(value: { id: number; mode: HistoryType }) {
    this.__data = value;

    if (value) {
      this.mode = value.mode;
      this.dateFrom.setValue(new Date().setMonth(new Date().getMonth() - 1));
      this.dateTo.setValue(new Date());
    }
  }
  get data(): { id: number; mode: HistoryType } {
    return this.__data;
  }

  @Input() dataType: DataTypeCodeEnum = DataTypeCodeEnum.String;

  constructor(private historyApiService: HistoryApiService, private cdr: ChangeDetectorRef) {
    combineLatest([this.dateFrom.valueChanges, this.dateTo.valueChanges])
      .pipe(takeUntil(this._destroy$))
      .subscribe((_) => {
        this.setData(this.data.id);
      });
  }

  ngOnDestroy() {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  setData(id: number): void {
    if (!id) return;
    this.pending = true;

    let request$ = new Observable<IHistoryDto[] | null>();

    switch (this.mode) {
      case HistoryType.uniqueParam:
        request$ = this.historyApiService.getHistoryUnicParamById(
          id,
          dateToISOLikeButLocal(this.dateFrom.value),
          dateToISOLikeButLocal(this.dateTo.value, true, true),
        );
        break;
      case HistoryType.funcionalTypeParam:
        request$ = this.historyApiService.getHistoryFunctionalTypeParamById(
          id,
          dateToISOLikeButLocal(this.dateFrom.value),
          dateToISOLikeButLocal(this.dateTo.value, true, true),
        );
        break;
      case HistoryType.funcionalType:
        request$ = this.historyApiService.getHistoryFunctionalTypeById(
          id,
          dateToISOLikeButLocal(this.dateFrom.value),
          dateToISOLikeButLocal(this.dateTo.value, true, true),
        );
        break;
    }

    request$
      .pipe(
        finalize(() => {
          this.pending = false;
          this.cdr.detectChanges();
        }),
      )
      .subscribe((resp) => {
        this.historyData = [];
        let newDataType = DataTypeCodeEnum.String;
        let oldDataType = DataTypeCodeEnum.String;

        resp = resp.reverse();
        resp.forEach((item) => {
          this.historyData.unshift({
            changeDate: item.changeDate,
            changeTimes: [
              // разварачиваем обычные values
              ...item.changes
                .reverse()
                .map((change, i) => {
                  //ищем  у кого fieldName: Тип данных затем вытаскиваем из них newValue и oldValue
                  const newDataTypeHelper =
                    DataTypeCodeEnumRu[
                      change.values.find((item) => item.fieldName === EHistoryFieldNames.dataType)?.newValue
                    ];
                  const oldDataTypeHelper =
                    DataTypeCodeEnumRu[
                      change.values.find((item) => item.fieldName === EHistoryFieldNames.dataType)?.oldValue
                    ];

                  if (newDataTypeHelper && oldDataTypeHelper) {
                    newDataType = newDataTypeHelper as DataTypeCodeEnum;
                    oldDataType = oldDataTypeHelper as DataTypeCodeEnum;
                  } else {
                    oldDataType = newDataType;
                  }

                  return {
                    time: change.time.slice(0, 8),
                    values: [
                      ...(change.values.length
                        ? change.values
                            .map(
                              (value) =>
                                new HistoryChangeTimeFabric({
                                  action: change.action,
                                  author: change.author,
                                  oldDataType:
                                    value.fieldName === 'Допустимые значения' ? oldDataType : DataTypeCodeEnum.String,
                                  newDataType:
                                    value.fieldName === 'Допустимые значения' ? newDataType : DataTypeCodeEnum.String,
                                  fieldName: value.fieldName,
                                  oldValue: value.oldValue,
                                  newValue: value.newValue,
                                }),
                            )
                            .filter((value) => value.newValue[0] || value.oldValue[0])
                        : [{ action: change.action, author: change.author }]),
                    ],
                  };
                })
                .reverse(),

              // разварачиваем relatedEntities
              // 3 уровня вложенности(change > relatedEntity > relatedEntityAction) из каждого берем немного информации
              ...item.changes.reduce((changesAccumulator, change) => {
                changesAccumulator = [
                  ...changesAccumulator,
                  ...change.relatedEntities.reduce((relatedEntityAccumulator, relatedEntity) => {
                    relatedEntityAccumulator = [
                      ...relatedEntityAccumulator,
                      ...relatedEntity.relatedEntityActions.reduce(
                        (relatedEntityActionAccumulator, relatedEntityAction) => {
                          relatedEntityActionAccumulator = [
                            ...relatedEntityActionAccumulator,
                            {
                              time: relatedEntityAction.time,
                              values: [
                                new HistoryChangeTimeFabric({
                                  author: change.author,
                                  fieldName: relatedEntity.name,
                                  action: relatedEntityAction.action,
                                  oldDataType,
                                  newDataType,
                                  oldValue: relatedEntityAction.oldValues.map((r) => r.parentEntityValue),
                                  newValue: relatedEntityAction.newValues.map((r) => r.parentEntityValue),
                                }),
                              ].filter((value) => value.newValue[0] || value.oldValue[0]),
                            },
                          ];
                          return relatedEntityActionAccumulator;
                        },
                        [],
                      ),
                    ];
                    return relatedEntityAccumulator;
                  }, []),
                ];
                return changesAccumulator;
              }, []),
            ],
          });
        });

        //сортируем по времени
        this.historyData = this.historyData.map((data) => ({
          ...data,
          changeTimes: data.changeTimes
            .reduce((acc, timeObject) => {
              //TODO временно отсекаем Атрибуты, когда будет готов бек нуно удалить
              timeObject = {
                ...timeObject,
                values: timeObject.values.filter((item) => item.fieldName !== 'Атрибуты'),
              };
              //=========================

              const findedTimeObject = acc.find((item) => item.time === timeObject.time);
              findedTimeObject
                ? (findedTimeObject.values = [...findedTimeObject.values, ...timeObject.values])
                : acc.unshift(timeObject);
              return acc;
            }, [])
            .reverse(),
        }));
      });
  }
}
