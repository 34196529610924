import { MaterialParameterDto } from '@common/interfaces';
import { BindingsCheckParameter } from '@features/bindings-check/interfaces';

export function materialsCheckParametersAdapter(parameters: MaterialParameterDto[]): BindingsCheckParameter[] {
  return parameters
    .filter((f) => !!f.uniqueParameterId)
    .map((p) => ({
      id: p.uniqueParameterId,
      description: p.description,
      uniqueParameterName: p.uniqueParameterName,
      valueDescription: null,
      valueStr: p.valueStr,
      dataType: p.dataType,
    }));
}
