import { Injectable } from '@angular/core';
import { ROUTES_PARTS } from '@common/consts';
import { BindingResultDto } from '@common/dto';
import { BindingsCheckService } from '@features/bindings-check/services';
import { BindingsCheckTreeNode, BindingsCheckTreeNodeType } from '@features/bindings-check/utils';
import { getEntityIdType } from '@features/bindings/shared/utils/get-entity-id-type';
import { BindingsApiService } from '@services/api';
import { omit, uniqBy } from 'lodash';
import { combineLatest, map, mergeMap, Observable, of } from 'rxjs';

function generateBindingsCheckTreeNode(data): BindingsCheckTreeNode {
  return {
    type: BindingsCheckTreeNodeType.Simple,
    expandable: false,
    name: '',
    value: data,
    children: [],
  };
}

@Injectable({
  providedIn: 'root',
})
export class NomenclatureUtilsService {
  constructor(private bindingsApiService: BindingsApiService, public bindingsCheckService: BindingsCheckService) {}

  bindingsTreeCheckDataAdapter(data: BindingResultDto): BindingsCheckTreeNode[] {
    return uniqBy(data?.nomenclatures, (n) => n.bindingId)?.map((nomenclature) => {
      nomenclature.variants = nomenclature?.variants?.filter((v) => !!v);
      const units = nomenclature?.variants?.length ? nomenclature.variants[0]?.units : null;
      const nomenclatureNodeChildren: BindingsCheckTreeNode[] = [
        {
          type: BindingsCheckTreeNodeType.Simple,
          expandable: false,
          name: 'BINDINGS_CHECK_MODULE.BINDING_ID',
          children: [],
          isAsyncLink: true,
          value: nomenclature.bindingId,
        },
        {
          type: BindingsCheckTreeNodeType.Simple,
          expandable: false,
          name: 'COMMON_LABELS.GROUP_NUMBER',
          children: [],
          value: nomenclature.groupNumber,
        },
        {
          type: BindingsCheckTreeNodeType.Quantity,
          expandable: false,
          name: 'BINDINGS_CHECK_MODULE.QUANTITY',
          children: [],
          nomenclature: {
            ...omit(nomenclature, 'variants'),
            units,
          },
          value: nomenclature.quantity + '',
        },
        {
          type: BindingsCheckTreeNodeType.Simple,
          expandable: false,
          name: 'COMMON_LABELS.STATUS',
          children: [],
          value: nomenclature.linkStatus + '',
        },
      ];

      if (nomenclature?.variants?.length) {
        nomenclatureNodeChildren.push({
          type: BindingsCheckTreeNodeType.Simple,
          expandable: !!nomenclature?.variants?.length,
          name: 'COMMON_LABELS.NOMENCLATURE',
          children: nomenclature.variants?.map((variant) => {
            const variantChildren: BindingsCheckTreeNode[] = [
              {
                type: BindingsCheckTreeNodeType.Simple,
                expandable: false,
                name: 'ID',
                value: variant.nomenclatureId + '',
                children: [],
              },
              {
                type: BindingsCheckTreeNodeType.Simple,
                expandable: false,
                name: 'guid',
                value: variant.guid1C,
                children: [],
              },
              {
                type: BindingsCheckTreeNodeType.IsCp,
                expandable: false,
                name: 'BINDINGS_MODULE.OTHER.NOMENCLATURE_SOURCE',
                value: variant.isCp,
                children: [],
              },
            ];

            if (variant?.specifications?.length) {
              variantChildren.push({
                type: BindingsCheckTreeNodeType.Simple,
                expandable: !!variant?.specifications?.length,
                name: 'COMMON_LABELS.SPECIFICATIONS',
                children: variant.specifications?.map((specification) => ({
                  expandable: false,
                  name: specification.specification.name,
                  children: [],
                  type: BindingsCheckTreeNodeType.Simple,
                  value: specification.value,
                })),
                value: nomenclature.linkStatus,
              });
            }

            return {
              expandable: !!variantChildren?.length,
              type: BindingsCheckTreeNodeType.Variant,
              variant: omit(variant, 'specifications'),
              name: variant.name,
              children: variantChildren,
            };
          }),
        });
      }

      if (nomenclature?.matchingErrors?.length) {
        nomenclatureNodeChildren.push({
          type: BindingsCheckTreeNodeType.Simple,
          expandable: true,
          name: 'COMMON_LABELS.NOMENCLATURE',
          children: nomenclature.matchingErrors?.map((value) => generateBindingsCheckTreeNode(value)),
        });
      }

      return {
        expandable: !!nomenclatureNodeChildren?.length,
        name: nomenclature.groupName,
        type: BindingsCheckTreeNodeType.Nomenclature,
        nomenclature: {
          ...omit(nomenclature, 'variants'),
          units: nomenclature?.variants?.length ? nomenclature.variants[0].units : null,
        },
        children: nomenclatureNodeChildren,
      };
    });
  }

  getNomenclatureUrl(bindingId: number): Observable<string> {
    return this.bindingsApiService.getById(bindingId).pipe(
      mergeMap((binding) => {
        return combineLatest([this.bindingsCheckService.sourceType$, of(binding)]);
      }),
      map(([sourceType, binding]) => {
        const entityIdType = getEntityIdType(binding);
        const link =
          ROUTES_PARTS.BINDINGS +
          '/' +
          sourceType +
          '/' +
          entityIdType._bindingEntityType +
          '/' +
          entityIdType._bindingEntityId +
          '?' +
          new URLSearchParams({ group: binding.bindingGroupId.toString(), binding: binding.id.toString() });
        return link;
      }),
    );
  }
}
