import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment/moment';

@Pipe({
  name: 'historyDate',
})
export class HistoryDatePipe implements PipeTransform {
  transform(value: string): string {
    return moment(value).format('DD.MM.YYYY');
  }
}
