import { Component, Input } from '@angular/core';
import { ThemeColors } from '@common/consts';
import { ParameterValueType } from './types';
import { parseValue } from './utils/parse-value';
import { DataTypeCodeEnum } from '@common/enums';
import { ActivatedRoute, Router } from '@angular/router';
import { ParameterViewService } from './services/parameter-view.service';

@Component({
  selector: 'app-parameters-view',
  templateUrl: './parameters-view.component.html',
  styleUrls: ['./parameters-view.component.scss'],
})
export class ParametersViewComponent {
  isMaterialParam = false;
  @Input() set dataType(value: DataTypeCodeEnum) {
    this._dataType = value;
    this.isMaterialParam = value === DataTypeCodeEnum.Material;
    this.textColor = ThemeColors.DarkBlue;
    if (this.isMaterialParam) {
      this.textColor = ThemeColors.Gray5;
    }
  }

  @Input() set value(value: string) {
    this._value = value;
    this.rowValues = value ? value.split('|') : [];
    this.values = value ? value.split('|').map((v) => parseValue(v, this._dataType)) : [];
  }

  @Input() set alert(value: boolean) {
    this.textColor = value ? ThemeColors.Orange : ThemeColors.DarkBlue;
  }
  @Input() maxCount = 5;
  @Input() disableMaterialLink = false;

  public readonly ThemeColors = ThemeColors;
  protected readonly DataTypeCodeEnum = DataTypeCodeEnum;

  isShowAllCount = false;

  _value: string;
  _dataType: DataTypeCodeEnum;

  ParameterValueType = ParameterValueType;
  textColor: ThemeColors = ThemeColors.DarkBlue;

  values: { value?: string; type: ParameterValueType; intervalValue?: string[] }[];
  rowValues: string[];

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    public parameterViewService: ParameterViewService,
  ) {}

  toggle() {
    this.isShowAllCount = !this.isShowAllCount;
  }

  navigateToTab(
    tabPath: 'familyVersionFunctionalTypeParameters' | 'symbolMaterials' | 'familyVersionParameters' | undefined,
  ) {
    if (!this.isMaterialParam || !tabPath || this.disableMaterialLink) return;
    this._router.navigate([`../${tabPath}`], { relativeTo: this._route });
  }
}
