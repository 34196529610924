<pik-drawer #drawer maxWidth="738px" style="min-width: 600px">
  <pik-drawer-header [ngSwitch]="drawerType">
    <h2>Параметры семейства</h2>
  </pik-drawer-header>

  <pik-drawer-content>
    <form [formGroup]="parameterForm" *ngIf="parameterForm">
      <fieldset *ngFor="let parameter of initialParameters$ | async">
        <pik-form-control>
          <legend>
            <span>{{ parameter.uniqueParameterName }}</span>
            <fm-info-tooltip [text]="parameter.description" *ngIf="parameter.description"></fm-info-tooltip>
          </legend>
          <div>
            <app-parameter-value-control
              [dataTypeCode]="parameter?.dataType?.code"
              formControlName="{{ parameter.uniqueParameterName }}"
              [validValues]="parameter.valueDescription"
              [maxValuesCount]="1"
              [allowEmptyValue]="true"
            ></app-parameter-value-control>
          </div>
        </pik-form-control>
      </fieldset>
    </form>
  </pik-drawer-content>

  <pik-drawer-footer>
    <app-drawer-common-footer (save)="save()" (cancel)="close()"></app-drawer-common-footer>
  </pik-drawer-footer>
</pik-drawer>
