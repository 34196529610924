import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeColors } from '@common/consts';

@Pipe({
  name: 'stringWithSpecialSymbols',
})
export class ParameterViewStringWithSpecialSymbolsPipe implements PipeTransform {
  colors = ThemeColors;

  constructor(private sanitized: DomSanitizer) {}

  transform(text: string) {
    return this.sanitized.bypassSecurityTrustHtml(
      text
        .replace(/[' ']/g, '&nbsp')
        .replace(/[?]/gm, `<span style="color: ${this.colors.Green}">?</span>`)
        .replace(/[*]/gm, `<span style="color: ${this.colors.Green}">*</span>`),
    );
  }
}
