<h3>Результат обработки</h3>

<div class="bindings-check-tree">
  <ng-container *ngIf="bindingsCheckService.checkPending$ | async; else treeContent">
    <pik-loader></pik-loader>
  </ng-container>
  <ng-template #treeContent>
    <ng-container *ngIf="bindingsCheckService.checkMessage$ | async as message; else tree">
      <div class="message {{ message?.type }}">
        {{ message.text }}
      </div>
    </ng-container>

    <ng-template #tree>
      <nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource">
        <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine>
          <nz-tree-node-toggle *ngIf="node.expandable">
            <ng-container *ngIf="!treeControl.isExpanded(node); else expandedNode">
              <svg-icon pikButtonIcon src="./assets/icons/tree-angle.svg"></svg-icon>
            </ng-container>
            <ng-template #expandedNode>
              <svg-icon pikButtonIcon src="./assets/icons/tree-angle-active.svg"></svg-icon>
            </ng-template>
          </nz-tree-node-toggle>
          <div class="branch">
            <ng-container [ngSwitch]="node.type">
              <ng-container *ngSwitchCase="BindingsCheckTreeNodeType.Simple">
                {{ node.name | translate }}
                <ng-container *ngIf="node.value">
                  <app-binding-link *ngIf="node.isAsyncLink" [bindingId]="node.value">
                    : {{ node.value }}
                  </app-binding-link>
                  <span *ngIf="!node.isAsyncLink">: {{ node.value }}</span>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="BindingsCheckTreeNodeType.Nomenclature">
                <app-bindings-check-binding-branch [data]="node.nomenclature"></app-bindings-check-binding-branch>
              </ng-container>
              <ng-container *ngSwitchCase="BindingsCheckTreeNodeType.Variant">
                <app-bindings-check-nomenclature-branch
                  [nomenclature]="node.variant"
                ></app-bindings-check-nomenclature-branch>
              </ng-container>
              <ng-container *ngSwitchCase="BindingsCheckTreeNodeType.Quantity">
                <app-bindings-check-quantity-branch
                  [quantity]="node.value"
                  [nomenclature]="node.nomenclature"
                ></app-bindings-check-quantity-branch>
              </ng-container>
              <ng-container *ngSwitchCase="BindingsCheckTreeNodeType.IsCp">
                {{ node.name | translate }}:&nbsp;
                <pik-tag [color]="ThemeColors.Folder">
                  {{ (node.value ? 'COMMON_LABELS.EXTERNAL' : 'COMMON_LABELS.SNAP') | translate }}
                </pik-tag>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ node.name | translate }}
              </ng-container>
            </ng-container>
            <div class="children-counter" *ngIf="node?.children?.length">{{ node.children.length }}</div>
          </div>
        </nz-tree-node>
      </nz-tree-view>
    </ng-template>
  </ng-template>
</div>
