export const BindingStatus = {
  1: {
    name: 'COMMON.COMPLETE',
    color: '#27AE60',
  },
  2: {
    name: 'COMMON.REDACTION',
    color: '#0097ff',
  },
  3: {
    name: 'COMMON.APPROVING',
    color: '#F1882A',
  },
};
