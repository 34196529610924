import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PermissionService } from '@core/account/services';
import { Observable, tap } from 'rxjs';
import { Permissions } from '@core/account/types';
import { ROUTES_PARTS } from '@common/consts';

@Injectable({
  providedIn: 'root',
})
export class VisibilityOfSettingsGuardGuard implements CanActivate {
  constructor(private permissionService: PermissionService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.permissionService.asyncAccess(Permissions.ApplicationSettingsChange).pipe(
      tap((access) => {
        if (!access) {
          this.router.navigate([`${ROUTES_PARTS.NOT_FOUND}`], { skipLocationChange: true });
        }
      }),
    );
  }
}
