import { Component, Input, OnInit } from '@angular/core';
import { BindingCheckNomenclatureData } from '@features/bindings-check/utils';

@Component({
  selector: 'app-bindings-check-quantity-branch',
  templateUrl: './bindings-check-quantity-branch.component.html',
  styleUrls: ['./bindings-check-quantity-branch.component.scss'],
})
export class BindingsCheckQuantityBranchComponent implements OnInit {
  @Input() quantity: number;
  @Input() set nomenclature(value: BindingCheckNomenclatureData) {
    this._nomenclature = value;
  }

  _nomenclature: BindingCheckNomenclatureData;

  constructor() {}

  ngOnInit(): void {}
}
