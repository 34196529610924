import { FullVersionParameterDto } from '@common/dto';
import { BindingsCheckParameter } from '@features/bindings-check/interfaces';

export function familyVersionParametersAdapter(parameters: FullVersionParameterDto[]): BindingsCheckParameter[] {
  return parameters
    .filter((f) => !!f.uniqueParameter?.id)
    .map((p) => ({
      id: p.uniqueParameter.id,
      description: p.description,
      uniqueParameterName: p.uniqueParameter.name,
      valueDescription: p.valueDescription,
      valueStr: p.valueStr,
      dataType: p.dataType,
    }));
}
