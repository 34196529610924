import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPaths } from '@common/consts';

import { BindingResultDto } from '@common/dto';
import { BdsElementForSnapDto } from '@common/dto/';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BdsFmApiService {
  constructor(private http: HttpClient) {}
  public checkBindings(data: BdsElementForSnapDto): Observable<BindingResultDto[]> {
    return this.http.post<BindingResultDto[]>(`${ApiPaths.Bindings}/checkElements`, data);
  }
}
