import {
  BaseBindingResultDto,
  BaseBindingSpecificationDto,
  BaseBindingVariantDto,
  BindingResultDto,
} from '@common/dto';
import { omit, uniqBy } from 'lodash';

export enum BindingsCheckTreeNodeType {
  Nomenclature = 'Nomenclature',
  Quantity = 'Quantity',
  Simple = 'Simple',
  IsCp = 'IsCp',
  Variant = 'Variant',
}

export interface BindingCheckNomenclatureData extends Omit<BaseBindingResultDto, 'variants'> {
  units: string;
  suggestedNomenclature?: ISuggestedNomenclature;
}

export interface BindingsCheckTreeNode {
  expandable: boolean;
  name: string;
  type: BindingsCheckTreeNodeType;
  nomenclature?: BindingCheckNomenclatureData;
  variant?: Omit<BaseBindingVariantDto, 'specifications'>;
  specification?: BaseBindingSpecificationDto;
  value?: string | number | boolean;
  isAsyncLink?: boolean;
  children: BindingsCheckTreeNode[];
}

export interface ICheckElement {
  bdsId: string;
  elements: BindingsCheckTreeNode[];
}

export interface ISuggestedNomenclature {
  nomenclatureKind: string;
  specificationValues: SpecificationValue[];
}

interface SpecificationValue {
  specification: string;
  value: string;
}
